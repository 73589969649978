const { Axios } = require('../libs/axios');

const getUserProfile = async () => {
  const { data } = await Axios.get('/api/user/getProfile');
  return data;
}

const updateTradeURL = async (tradeURL) => {
  const { data } = await Axios.post('/api/user/updateTradeURL', {tradeURL});
  return data;
}

const getUserDetails =  async () => {
  const { data } = await Axios.get('/api/user/getDetails');
  return data;
}

const sendTrade = async (items) => {
  const { data } = await Axios.post('/api/user/withdrawItems', {items});
  return data;
}

const logout = async () => {
  const { data } = await Axios.post('/auth/logout');
  return data;
}
export {
  getUserProfile,
  updateTradeURL,
  getUserDetails,
  sendTrade,
  logout
}