import React, { useState } from 'react';
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Cached, PlaylistAdd, PlaylistRemove } from '@mui/icons-material';

const FiltersPanelStyled = styled('div')(({ theme }) => ({
  backdropFilter: 'blur(10px)',
  background: 'rgb(182 204 254 / 29%)',
  boxShadow: theme.shadows[0],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '50px',
  position: 'sticky',
  top: '-0.5em',
  zIndex: 99,
  fontSize: '12px',
  color: theme.palette.grey[700],
  padding: '5px',
  width: '100%',
}));

const FiltersPanel = (
  {
    availableItems,
    selectedItems,
    amountAvailable,
    amountSelected,
    selectAllItems,
    unselectAllItems,
    updateInventory
  }) => {
  const [loading, setLoading] = useState(false);
  const updateAction = async () => {
    setLoading(true);
    const update = await updateInventory();
    setLoading(false)
  }
  return(
    <FiltersPanelStyled>
      <div>Available: <strong>{availableItems}</strong> | <strong>${amountAvailable.toFixed(2)}</strong></div>
      <div>Selected: <strong>{selectedItems}</strong> | <strong>${amountSelected.toFixed(2)}</strong></div>
      <div>
        <LoadingButton
          size="small"
          loading={loading}
          disabled={loading}
          onClick={() => updateAction()}
          color={'primary'}
        >
          <Cached fontSize="small"/>
        </LoadingButton>
        {
          selectedItems >= availableItems ?
          <IconButton color="primary" onClick={() => unselectAllItems()}>
            <PlaylistRemove fontSize="small"/>
          </IconButton>
            :
          <IconButton color="primary" onClick={() => selectAllItems()}>
            <PlaylistAdd fontSize="small"/>
          </IconButton>
        }
      </div>
    </FiltersPanelStyled>
  )
}

export default FiltersPanel;