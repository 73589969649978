import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import InputComponent from "../../components/InputComponent";
import Typography from "@mui/material/Typography";
import { getUserProfile, updateTradeURL } from "../../api";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";

const SettingsPage = () => {
  const [userProfile, setUserProfile] = useState({
    steamId: '',
    tradeURL: '',
    displayName: '',
  })
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    const query = getUserProfile().then( value => {
      if(value.success) {
        setUserProfile({...value.data})
      }
    });
  },[])
  const updateLink = async () => {
    const link = userProfile.tradeURL
    if(link.length < 50 ) {
      return enqueueSnackbar('Error.', {variant: 'error'})
    }
    const query = await updateTradeURL(link);
    if(!query.success) {
      return enqueueSnackbar(query.message, {variant: 'error'})
    }
    return enqueueSnackbar(query.message, {variant: 'success'})
  }
  return (
    <Box sx={{
      display: 'grid',
      gap: "30px",
      margin: "30px",
      borderRadius: '8px',
      grid: "inherit",
      overflow: 'auto',
      padding: '30px',
      justifyContent: 'center',
      background: (theme) => theme.palette.background.secondary,
    }}>
      <Typography variant="h5" >Settings:</Typography>
      <Box sx={{ display: 'grid', gap: '5px',
        gridTemplateColumns: 'fit-content(0)',
        gridGap: '10px',
        gridTemplateRows: 'repeat(auto-fit, 85px)'}}>
        <InputComponent disabled title="Nickname" value={userProfile.displayName} placeholder="Nickname" />
        <InputComponent disabled title="SteamID" value={userProfile.steamId} placeholder="SteamID" />
        <Box display={'grid'} gap={'10px'}>
          <InputComponent onChange={(e) => setUserProfile((prevState => ({...prevState, tradeURL: e.target.value})))} title="Tradeoffer" value={userProfile.tradeURL} placeholder="Tradeoffer link." />
          <Button onClick={updateLink} variant={"outlined"} color={'success'}>Save</Button>
          <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" target="_blank">Find here.</a>
        </Box>
      </Box>
    </Box>
  )
}

export default SettingsPage;