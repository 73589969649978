import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import { useSnackbar } from 'notistack';
import FiltersPanel from "../FiltersPanel";
import Timer from "../Timer";
import { useEffect, useState } from "react";
import _ from "lodash";
import { sendTrade } from "../../api";

const getRarityColor = (rarity) => {

  const palette = {
    'Consumer Grade': '#afafaf',
    'Industrial Grade': '#6496e1',
    'Mil-spec Grade': '#4b69cd',
    'Restricted': '#8847ff',
    'Classified': '#d32ce6',
    'Covert': '#eb4b4b',
    'Contraband': '#e8c445',
    'Common': '#afafaf',
    'Uncommon': '#6496e1',
    'Rare': '#4b69cd',
    'Mythical': '#8847ff',
    'Legendary': '#d32ce6',
    'Ancient': '#eb4b4b',
    'Immortal': '#e8c445',
    'High Grade': '#4b69cd',
    'Remarkable': '#8847ff',
    'Exotic': '#d32ce6',
    'Extraordinary': '#eb4b4b',
    'Distinguished': '#4b69cd',
    'Exceptional': '#8847ff',
    'Superior': '#d32ce6',
    'Master': '#eb4b4b',
  }
  const keys = Object.keys(palette)
  const find = function (arr, find) {
    const name = find?.split(' ');
    return _.intersection(name, arr)
  };
  return palette[find(keys, rarity)[0]] ?? 'black';
}

const Item = styled('div')(({ theme, checked, rarity }) => ({
  backgroundColor: !checked ? theme.palette.background.default : theme.palette.common.white,
  cursor: 'pointer',
  boxShadow: checked && theme.shadows[0],
  padding: '10px',
  gap: 5,
  borderRadius: theme.shape.borderRadius[0],
  overflow: 'hidden',
  transition: '0.1s',
  '&:hover': {
    boxShadow: theme.shadows[1],
    transform: 'scale(1.1)',
    transition: '0.1s',
    fontWeight: 900
  },
  '&:hover img': {
    filter: `drop-shadow(${getRarityColor(rarity)} -2px 5px 9px)`,
    transform: 'scale(1.1)',
    transition: '0.3s',
  },
}));
const ItemPrice = styled('div')(({ theme, checked }) => ({
  fontSize: '23px',
  color: !checked ? '#534fff' : '#9b3fff',
  fontWeight: 500
}))
const ItemName = styled('div')(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 700,
  color: theme.palette.grey[900],
  overflow: 'hidden'
}))
const ItemQuality = styled('div')(({ theme }) => ({
  fontSize: '8px',
  fontWeight: 500,
  color: theme.palette.grey[700]
}))
const ItemIcon = styled('div')(({theme, checked}) => ({
  height: '110px',
  justifySelf: 'center',
  overflow: checked && 'hidden',
  'img': {
    width: checked && '100%',
    height: !checked && '100%',
    transition: '0.3s',
  }
}))

const TransferList = ({ items, isHold, timer, updateAll }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [available, setAvailable] = React.useState([]);
  const [cart, setCart] = React.useState([
  ]);
  const [availableSum, setAvailableSum] = React.useState(0);
  const [selectedSum, setSelectedSum] = React.useState(0);
  const [hold, setHold] = useState(false);
  const [time, setTime] = useState(false);
  useEffect(() => {
    setAvailable(items)
    setHold(isHold)
    setTime(timer)
  }, [items, isHold, timer])

  useEffect(() => {
    const sum = reduceSum(available)
    setAvailableSum(sum)
  }, [available])
  const handleToggle = (value) => () => {
    const currentIndex = cart.indexOf(value);
    const newCart = [...cart];

    if (currentIndex === -1) {
      newCart.push(value);
      const sum = reduceSum(newCart)
      setSelectedSum(sum)
    } else {
      newCart.splice(currentIndex, 1);
      const sum = reduceSum(newCart)
      setSelectedSum(sum)
    }
    setCart(newCart);
  };
  const selectAllItems = () => {
    setCart(available);
    const sum = reduceSum(available)
    setSelectedSum(sum)
  }
  const unselectAllItems = () => {
    setCart([]);
    setSelectedSum(0)
  }
  const reduceSum = (arr) => {
    return arr.reduce((total, item) => {
      total += item.prices.safe;
      return total;
    }, 0);
  }
  const withdrawItems = async (variant) => {
    if(hold) {
      return enqueueSnackbar('Your items in hold.', { variant });
    }
    const withdraw = await sendTrade(available);
    return enqueueSnackbar(withdraw.message, { variant: withdraw.success ? 'success' : 'error' });
  };
  const customList = (items) => {
    return(
      <>
        {items.map((item, key) => {
          return (
            <Item rarity={item.rarity} sx={{ display: 'grid', grid: 'min-content 2fr 14px/auto'}} checked={cart.indexOf(item) !== -1} role="listitem" onClick={handleToggle(item)} key={item.market_hash_name+key}>
              <ItemIcon checked={cart.indexOf(item) !== -1}><img src={item.image} alt={item.market_hash_name}/></ItemIcon>
              <ItemPrice checked={cart.indexOf(item) !== -1}>${item.prices.safe.toFixed(0)}</ItemPrice>
              <ItemName>{item.market_hash_name.split(' (')[0]}</ItemName>
              <ItemQuality>{item.market_hash_name.split(' (')[1]?.split(')')[0] ?? ''}</ItemQuality>
            </Item>
          );
        })}
      </>
    );
  }

  return (
    <Box sx={{
      display: 'grid',
      gap: "30px",
      padding: "30px",
      grid: "1fr 0fr 100px/3fr 0fr minmax(300px, 1fr)",
      overflow: 'auto',
      gridTemplateAreas: `
        "leftItems leftItems rightItems"
        "leftItems leftItems rightItems"
        "leftItems leftItems withdraw"
      `,
    }}>
      <Box sx={{
        background: (theme) => theme.palette.background.secondary,
        overflow: 'auto',
        gridArea: 'leftItems',
        borderRadius: '8px',
        display: 'grid',
        grid: 'auto 1fr / auto'
      }}>
        <FiltersPanel
          selectAllItems={selectAllItems}
          unselectAllItems={unselectAllItems}
          availableItems={available.length}
          amountAvailable={availableSum}
          amountSelected={selectedSum}
          selectedItems={cart.length}
          updateInventory={updateAll}
        />
        <Box sx={{
          background: (theme) => theme.palette.background.main,
          display: 'grid',
          gap: '10px',
          padding: '10px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
          gridAutoRows: '200px',
          position: 'relative',
          borderRadius: '8px',
        }}>
          {available.length === 0 ?
            <div style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <strong>Available items not found.</strong>
            </div> :
            customList(available)
          }
        </Box>
      </Box>
      <Box sx={{
        gridArea: 'rightItems',
        display: 'grid',
        overflow: 'auto',
        background: (theme) => theme.palette.background.secondary,
        borderRadius: '8px',
        gap: '10px',
        padding: '10px',
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
        gridAutoRows: '200px',
        position: 'relative'
      }}>
        {cart.length === 0 ?
          <div style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <strong>Your cart is empty.</strong>
          </div> :
          customList(cart)
        }
      </Box>
      <Box sx={{ gridArea: 'withdraw', alignItems: 'end', display: 'flex'}}>

        {
          hold ? (
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color="error"
              size="small"
              onClick={() => withdrawItems('error')}
              disabled={cart.length === 0}
              aria-label="move all left"
            >
              <Timer deadline={time}/>
            </Button>
          ) : (
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              color="primary"
              size="large"
              disabled={cart.length === 0}
              aria-label="move all left"
              onClick={() => withdrawItems()}
            >
              Withdraw
            </Button>
          )
        }
      </Box>
    </Box>
  );
}
export default TransferList;