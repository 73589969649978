import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  CircularProgress,
  Fade,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Typography from "@mui/material/Typography";
import Moment from 'react-moment';
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";
import useAuth from "./hooks/AuthHook";
import { getUserDetails, getUserProfile } from "./api";
import io from './libs/socket';
import AboutPage from "./pages/AboutPage";

const Router = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { user, isReady } = useAuth();
  const [placement, setPlacement] = React.useState();
  const [alerts, setAlerts] = React.useState([]);
  const [items, setItems] = useState([]);
  const [isHold, setIsHold] = useState(false);
  const [timer, setTimer] = useState('');

  useEffect(() => {
    getUserDetails().then(res => {
      const {items, timer, isHold, alerts} = res.data;
      setItems(items);
      setTimer(timer);
      setIsHold(isHold);
      setAlerts(alerts.reverse());
    });
  }, [user]);
  const updateAll = async () => {
    getUserDetails().then(res => {
      const {items, timer, isHold, alerts, success} = res.data;
      setItems(items);
      setTimer(timer);
      setIsHold(isHold);
      setAlerts(alerts.reverse());
      return success;
    });
  }
  useEffect(() => {
    io.on('alertListener', (alert) => {
      console.log('alert', alert)
      setAlerts(prev => [alert, ...prev]);
    })
  }, [])

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const getTypeAlert = (type) => {
    const types = {
      success: <CheckCircleRoundedIcon color="success" />,
      error: <ReportProblemRoundedIcon color="error" />,
      info: <InfoIcon color="primary" />,
    }
    return(types[type]);
  }
  const readAlert = (alertItem) => {
    io.emit('readAlert', alertItem);
    const updatedAlert = alerts.map(alert => {
      if (alert._id === alertItem._id) {
        return { ...alert, read: true };
      }
      return alert;
    });
    setAlerts(updatedAlert);
  }
  return(
    <BrowserRouter>
      { !isReady ?
        <Backdrop
          sx={{ color: '#fff' }}
          open={!isReady}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        :
        <>
          { user.isAuthenticated ?
            <Box sx={{
              display: 'grid',
              height: '100%',
              gap: '30px',
              gridTemplateRows: 'auto',
              gridTemplate: 'auto 1fr / auto',
              gridTemplateAreas:`
                "navbar"
                "main"
                "main"
                `
            }}>
              <Popper style={{zIndex:'999'}} open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)',width: '345px', maxHeight: '500px', overflow: 'auto'}}>
                      <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0.5}
                        marginX={0.5}
                      >
                        { alerts.length > 0 ? alerts.map((alert) => (
                            <ListItem onMouseEnter={() => !alert?.read && readAlert(alert)} sx={{px: 0, overflow: 'hidden', background: !alert.read && "#dee0e4a8", borderRadius: '8px' }} alignItems="center" button>
                              <ListItemIcon sx={{justifyContent: 'center'}}>
                                {
                                  getTypeAlert(alert?.type)
                                }
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    {alert?.title}
                                    <Typography mr="5px" sx={{color: 'rgba(0, 0, 0, 0.6)'}} variant="caption" gutterBottom>
                                      <Moment fromNow>{alert?.createdAt}</Moment>
                                    </Typography>
                                  </Box>
                                }
                                secondary={
                                  <React.Fragment>
                                    {alert?.text}
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          ))
                          :
                          <ListItem sx={{overflow: 'hidden'}} alignItems="flex-start">
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  {'🔔 No notifications.'}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        }
                      </Stack>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <ResponsiveAppBar user={user} alertsCount={alerts.filter((alert) => !alert?.read ).length} openAlerts={() => handleClick('bottom-start')} sx={{ gridArea: 'navbar' }} />
              <Routes sx={{ gridArea: 'main' }}>
                <Route path="/" element={<MainPage updateAll={updateAll} isHold={isHold} timer={timer} items={items} />} exact />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/success" element={<MainPage updateAll={updateAll} isHold={isHold} timer={timer} items={items} />} />
                <Route path="/About" element={<AboutPage />} />
              </Routes>
            </Box>
            :
            <LoginPage />
          }
        </>
      }

    </BrowserRouter>

  )
}

export default Router;