import React from 'react';
import TransferList from "../../components/TransferList";

const MainPage = ({ items, isHold, timer, updateAll }) => {
  return(
    <>
      <TransferList updateAll={updateAll} isHold={isHold} timer={timer} items={items} />
    </>
  )
}

export default MainPage;