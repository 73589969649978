import React from 'react';

const SteamLogin = ({ url, loginHandler, children }) => {
  const openWindow = () => {
    const win = window.open(url, '_blank',"top=125, left=25, width=1050,height=855")
    const interval = setInterval(() => {
      if(win.location.pathname === "/success") {
        console.log('done')
        win.close()
        clearInterval(interval)
        loginHandler()
      }
    },500)
  }
  return (
    <div onClick={() => openWindow()}>
      {children}
    </div>
  );
};

export default SteamLogin;