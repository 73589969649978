import React  from 'react';
import Box from "@mui/material/Box";
import InputComponent from "../../components/InputComponent";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AboutPage = () => {
  return (
    <Box sx={{
      display: 'grid',
      gap: "30px",
      margin: "30px",
      borderRadius: '8px',
      grid: "auto 1fr / auto",
      overflow: 'auto',
      padding: '30px',
      background: (theme) => theme.palette.background.secondary,
    }}>
      <Typography variant="h5" >About:</Typography>
      <Box sx={{ display: 'grid', gap: '5px',
        gridTemplateColumns: 'fit-content(0)',
        gridGap: '10px',
        grid: 'auto / 1fr'
        }}>
        <Box>
          <Accordion sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><strong>What is the purpose of this platform?</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This platform was created for employees of an advertising agency to promote sites that specialize in the Steam platform.

              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>What is the purpose of the work?</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Employees of our platform promote one of the many sites. For this, they get paid in game stuff. These items have their own value. For example, an in-game item may have a value, and cost either $10 or $1,000+. The value of an item is determined by the Steam community.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>How can I start working for your company? </strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Alas, our company has a clear limit on the number of employees. You can only get access to the site promotion through a special agent. Usually our agents find suitable employees themselves. The number of places is limited at the moment.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>How often can I get paid?</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We usually pay once or twice a week. The more you work with our company, the more often you get paid. The most active users can get paid up to $5000 per week.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>Is it legal?</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, it's a completely legal job. You promote popular sites with your steam profile. You play games and people see the site in your nickname or profile picture. This is advertising.
              Our company gets money for it and we, in our turn, pay you.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'saturate(180%) blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>How much time do I have to withdraw items?</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              After receiving the items on the site, you can withdraw them to Steam within 7 days. After the expiration date, they will be removed from your website inventory.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'saturate(180%) blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>Why i cant withdraw items on Steam?</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can't get items on Steam for several reasons. If you have problems with a trade, i.e. your trade is not active and our bot cannot create a trade with you. And also because of the limitations in the amount of inventory of your steam inventory.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'saturate(180%) blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>Why do you pay in game items and not real money?
            </strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Our services are used by hundreds of sites, as well as we have thousands of employees. In order to fully automate the process and save ourselves from unnecessary bureaucracy it was decided to pay with game items, which have real value. This is due to many factors, such as: taxes, registration of a legal entity, the countries that support commercial activities as well as legal issues in this area. That to give our clients the maximum convenience, not to ask them to confirm the identity, not to demand to confirm the passport data etc., we decided to pay by means of game things.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        <Accordion sx={{backdropFilter: 'saturate(180%) blur(10px)', background: 'rgb(255 255 255 / 37%)'}} disableGutters>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>If I still have questions, who can I discuss them with?
            </strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can ask your personal agent any questions you have. Their job also includes supporting clients, answering frequently asked questions, solving problems, and so on.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
    </Box>
  )
}

export default AboutPage;