import React, { useContext, useEffect } from 'react';
import Router from './routes';
import './App.css';
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./themes";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { ProvideAuth } from "./hooks/AuthHook";
function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider
            maxSnack={3}
            sx={{ maxWidth: 400 }}
            autoHideDuration={20000}
          >
            <CssBaseline />
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </ProvideAuth>
    </div>
  );
}

export default App;
